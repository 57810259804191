import React, { useState } from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import en from "../locales/en.yml";
import fi from "../locales/fi.yml";
import ImgItem from "./ImgItem";
import theme from "../styles/theme";

class RelatedCoins extends React.Component {
  render() {
    const text = this.props.locale === "fi-FI" ? fi : en;

    return (
      <>
        <h2>{this.props.relatedHeading}</h2>
        <div
          css={`
            margin: 0 auto;
            display: grid;
            max-width: 800px;
            justify-content: center;
            grid-gap: 20px;
            grid-template-columns: repeat(3, 1fr);
            position: relative;
            ${theme.tablet} {
              grid-template-columns: repeat(3, 1fr);
            }
            ${theme.mobile} {
              grid-template-columns: repeat(1, 1fr);
            }
            .item {
              border-radius: 10px;
              background: #fff;
              box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.04);
              overflow: hidden;
              display: flex;
              flex-direction: column;
              transition: all 0.2s;
              border: 1px solid rgba(100, 100, 100, 0.04);
              cursor: pointer;
              a {
                padding: 20px 0 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
              }
              .imgCoin {
                margin-bottom: 0;
                max-width: 100%;
              }
              &:hover {
                box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.18);
              }
            }
            h2 {
              margin: 20px 0 20px;
              font-size: 20px;
              text-align: center;
              padding: 0 20px;
              min-height: 62px;
            }
            p {
              font-family: freight-text-pro, serif;
              text-align: center;
              line-height: 1.4;
              color: #646464;
            }
            .topContent {
            }
            .middleContent {
              display: flex;
              padding: 20px;
              flex: 1;
              position: relative;
              justify-content: center;
              align-items: center;
              min-height: 240px;
            }
            .bottom {
              background: #f2f2f2;
              width: 100%;
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-family: nimbus-sans;
            }
            .bottomContent {
              padding: 10px 20px 20px;
              width: 100%;
              flex-direction: column;
              justify-content: center;
              display: grid;
              grid-gap: 5px;
              grid-template-columns: repeat(2, 1fr);
              p.gridItem {
                font-family: nimbus-sans, sans-serif;
                font-size: 14px;
                text-align: left;
                margin: 0;
              }
            }
          `}
        >
          {this.props.relatedCoins.length > 0 &&
            this.props.relatedCoins.map(item => {
              const currency =
                item.currency === "euro"
                  ? text.coin.euro
                  : item.currency === "Euro"
                  ? text.coin.euro
                  : item.currency === "Markka"
                  ? text.coin.marks
                  : item.currency === "markka"
                  ? text.coin.marks
                  : item.currency === "Penniä"
                  ? text.coin.pennies
                  : item.currency === "Penni"
                  ? text.coin.pennies
                  : null;

              const type =
                item.commemorative == 1
                  ? text.coin.commemorative
                  : text.coin.spendingMoney;

              const value = `${item.faceValue} ${currency}`;
              const title = item.title;
              const itemMaterial =
                item.characteristicMaterial === "Base metal"
                  ? text.coin.basicMetal
                  : item.characteristicMaterial === "Silver"
                  ? text.coin.silver
                  : item.characteristicMaterial === "Gold"
                  ? text.coin.gold
                  : null;

              const facts = [value, item.years, itemMaterial, type];

              const imgData = item.imagesCollection.items[0].imageUrl;
              let imgUrl;
              if (imgData.includes("wp-content")) {
                imgUrl = imgData.includes("scaled")
                  ? imgData.replace("scaled", "300x300")
                  : imgData.replace(".jpg", "-300x300.jpg");
              } else {
                imgUrl = imgData;
              }
              let slug =
                this.props.locale === "fi-FI"
                  ? `/kolikko/${item.slug}`
                  : `/en/coin/${item.slug}`;

              return (
                <Link to={slug} className="item" key={item.coinid}>
                  <div className="middleContent">
                    <ImgItem key={imgUrl} data={imgUrl} />
                  </div>
                  <div className="bottom">
                    <h2>
                      {title}{" "}
                      {item.commemorative == 1 && (
                        <>
                          {item.faceValue}&nbsp;{currency}
                        </>
                      )}
                    </h2>
                  </div>
                </Link>
              );
            })}
        </div>
      </>
    );
  }
}

export default RelatedCoins;
