import { graphql } from "gatsby";
import React from "react";
import "styled-components";
import ImageGallery from "../components/ImageGallery";
import Layout from "../components/Layout";
import RelatedCoins from "../components/RelatedCoins";
import { Coiniverse } from "../components/Svgs";
import en from "../locales/en.yml";
import fi from "../locales/fi.yml";
import theme from "../styles/theme";

const Coin = ({ data, pageContext }) => {
  const coin = data?.contentful?.coin;
  const allCoins = data?.contentful?.coins?.items;
  const globalText = data?.contentful?.global;
  const langLinks = {
    fi: `/kolikko/${data?.contentful?.slugFi?.slug}`,
    en: `/en/coin/${data?.contentful?.slugEn?.slug}`,
  };
  const locale = pageContext.locale;
  const text = locale === "fi-FI" ? fi : en;

  if (typeof coin === "undefined") {
    return (
      <>
        <p>Coin data is missing...</p>
      </>
    );
  }

  console.log(data);
  // Parse/filter "image source"
  const imageSources = coin.imagesCollection.items.map(i => i.source);
  const imageSourcesUnique = [...new Set(imageSources)];
  const imageSourcesFiltered = imageSourcesUnique.filter(
    i => !i.startsWith("http")
  );
  const imageSourcesString = imageSourcesFiltered?.join(", ");

  const coinSerie = coin.seriesRefCollection.items[0]?.serieName;

  // Is this coins serie in filter?
  const seriesInFilter = data.contentful.series.items.filter(
    item => item.useInFilters
  );
  const isInFilter = seriesInFilter.some(item => item.serieName === coinSerie);

  let relatedCoins = [];
  let relatedHeading = "";

  if (coinSerie) {
    relatedCoins = allCoins.filter(
      item => item.seriesRefCollection.items[0]?.serieName === coinSerie
    );
    relatedHeading = text.single.relatedHeadingSerie;
  }
  if (coin.commemorative == 0) {
    relatedCoins = allCoins.filter(item => item.commemorative == 0);
    relatedCoins = relatedCoins.sort(() => 0.5 - Math.random());
    relatedCoins = relatedCoins.filter(item => item.slug !== coin.slug);
    relatedHeading = text.single.relatedHeadingCircular;
  }

  const images = coin.imagesCollection.items.map(item => ({
    original: `${item.imageUrl}`,
    thumbnail: `${item.imageUrl}`,
  }));

  let designers = null;
  if (coin.designer?.length > 0) {
    const trimmedDesigners = coin.designer?.map(i => i.trim());
    designers = trimmedDesigners.join(", ");
  }

  const currency =
    coin.currencyDropdown === "euro"
      ? text.coin.euro
      : coin.currencyDropdown === "markka"
      ? text.coin.marks
      : null;

  const mintagesGroupedByYears = coin.mintagesCollection.items.reduce(function (
    r,
    a
  ) {
    r[a.year] = r[a.year] || [];
    r[a.year].push(a);
    return r;
  },
  Object.create(null));

  let mintagesGroupedByYearsArr = Object.keys(mintagesGroupedByYears).map(
    k => mintagesGroupedByYears[k]
  );

  return (
    <Layout
      locale={locale}
      title={coin.title}
      langLinks={langLinks}
      isSingleCoin
    >
      <div
        css={`
          padding-top: 120px;
          background: #f2f2f2;
        `}
      >
        <section
          css={`
            max-width: 1400px;
            width: 100%;
            margin: 0 auto;
          `}
        >
          <button
            onClick={() => window.history.back()}
            className="button"
            css={`
              background: none;
              border: none;
              align-self: flex-start;
              padding-left: 0;
              color: rgba(0, 0, 0, 0.6);
              letter-spacing: 0.02em;
              svg {
                margin-right: 6px;
              }
            `}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                fill="rgba(0, 0, 0, 0.6)"
              />
            </svg>
            <span>{text.single.goBack}</span>
          </button>
        </section>
      </div>
      <main
        css={`
          background: #f2f2f2;
          max-width: none;
          margin: 0 auto;
          padding: 0 10px 60px;
        `}
      >
        <article
          css={`
            display: flex;
            max-width: 1100px;
            margin: 0 auto;
            flex-direction: column;
            padding-top: 50px;
            .row {
              display: flex;
            }
            .row:first-of-type {
              margin-bottom: 50px;
            }
            .left,
            .right {
              flex: 1;
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
            }
            .left {
              width: 50%;
              padding-top: 20px;
            }
            .right {
              padding-left: 30px;
            }
            p {
              line-height: 1.5;
            }
            h1 {
              font-size: 42px;
              margin: 20px 0 20px;
            }
            h2 {
              font-family: nimbus-sans;
              font-weight: 700;
              font-size: 24px;
            }
            h3 {
              font-family: nimbus-sans;
              font-weight: 700;
              font-size: 16px;
              text-transform: uppercase;
              letter-spacing: 1px;
              opacity: 0.5;
            }
            .btnCoiniverse {
              display: none;
              margin: 30px 0;
            }
            .imgSources {
              opacity: 0.7;
              font-size: 15px;
            }
            .imgBox {
              width: 100%;
              margin: 0 auto;
              background: #fff;
              border-radius: 10px;
              padding: 20px;
            }
            .imgCoin {
              width: 100%;
            }
            .image-gallery {
              width: 90%;
              max-width: 500px;
              margin: 0 auto;
            }
            .image-gallery-thumbnails-container {
              margin: 40px;
              min-height: 200px;
              button.active,
              button:hover {
                border: none;
              }
            }
            .sweetLoader {
              position: absolute;
              width: 100%;
              top: 20%;
              left: 0;
              right: 0;
              text-align: center;
              opacity: 0.4;
            }
            .mobileTitle {
              display: none;
            }
            .imgSources {
            }
            .coinPlaceholder {
              animation: linear 1s placeHolderShimmer infinite;
              background: #f6f7f8;
              background: linear-gradient(
                to right,
                #eeeeee 8%,
                #dddddd 18%,
                #eeeeee 33%
              );
              background-size: 800px 104px;
              width: 100%;
              padding-bottom: 100%;
              border-radius: 50%;
              transform: translateZ(0);
            }
            @keyframes placeHolderShimmer {
              0% {
                background-position: -468px 0;
              }
              100% {
                background-position: 468px 0;
              }
            }
            ${theme.tablet} {
              .row:first-of-type {
                margin-bottom: 20px;
              }
              .desktopTitle {
                display: none;
              }
              .mobileTitle {
                display: flex;
                h1 {
                  margin-top: 30px;
                }
              }
              .row {
                flex-wrap: wrap;
                > div {
                  flex-basis: 100%;
                }
              }
            }
            ${theme.mobile} {
              .row:first-of-type {
                justify-content: center;
              }
              h1 {
                font-size: 32px;
                margin-top: 40px;
                text-align: center;
              }
              .left {
                padding-left: 0;
                padding-right: 0;
                max-width: 100%;
                ${images.length > 3 && "padding-bottom: 80px;"}
              }
              .right {
                padding-left: 0;
              }
            }
          `}
        >
          <>
            <div className="row mobileTitle">
              <h1>{coin.title}</h1>
            </div>
            <div className="row">
              <div className="left">
                <div className="imgBox">
                  <ImageGallery data={images} />
                </div>
                <p className="imgSources">
                  {imageSourcesUnique.length === 1
                    ? `${text.single.imageSource}: ${imageSourcesString}`
                    : `${text.single.imageSources}: ${imageSourcesString}`}
                </p>
              </div>
              <div className="right">
                <h1 className="desktopTitle">
                  {coin.title}{" "}
                  {coin.commemorative == 1 && (
                    <>
                      {coin.faceValue}&nbsp;{currency}
                    </>
                  )}
                </h1>
                <p
                  css={`
                    white-space: pre-wrap;
                  `}
                  dangerouslySetInnerHTML={{
                    __html: coin.descriptionText,
                  }}
                />
                <div
                  className="btnCoiniverse"
                  css={`
                    .button {
                      display: inline-flex;
                      height: auto;
                      display: flex;
                      align-items: center;
                      height: 48px;
                      border-radius: 24px;
                      padding-right: 20px;
                      svg {
                        margin: 0 10px;
                      }
                    }
                    .downloadIcons {
                      display: inline-flex;
                      display: none;
                      img {
                        max-height: 50px;
                        margin: 10px;
                      }
                    }
                  `}
                >
                  <button className="button">
                    <Coiniverse />
                    <span>{text.single.openApp}</span>
                  </button>
                  <div className="downloadIcons">
                    <a
                      href="https://apps.apple.com/fi/app/coiniverse/id1538929667?l=fi"
                      target="_blank"
                    >
                      <img src="/appstore.png" alt="Apple Download" />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?gl=US&hl=fi&id=fi.rahapaja.coiniverse"
                      target="_blank"
                    >
                      <img src="/play.png" alt="Android Download" />
                    </a>
                  </div>
                </div>

                <h2>{text.single.features}</h2>
                <table
                  css={`
                    border-collapse: collapse;
                    min-width: 100%;
                    max-width: 400px;
                    margin-bottom: 40px;
                    tr {
                      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    }

                    td {
                      padding: 6px 0;
                      width: 50%;
                    }
                  `}
                >
                  <tbody>
                    <tr>
                      <td>{text.single.year}</td>
                      <td>{coin.years}</td>
                    </tr>
                    <tr>
                      <td>{text.single.faceValue}</td>
                      <td>
                        {coin.faceValue} {currency}
                      </td>
                    </tr>
                    <tr>
                      <td>{text.single.type}</td>
                      <td>
                        {coin.commemorative == 1
                          ? text.single.commemorative
                          : text.single.spendingMoney}
                      </td>
                    </tr>
                    {designers && (
                      <tr>
                        <td>
                          {coin.designer.length > 1
                            ? text.single.designers
                            : text.single.designer}
                        </td>
                        <td>{designers}</td>
                      </tr>
                    )}
                    {coin.characteristicMaterial && (
                      <tr>
                        <td>{text.single.material}</td>
                        <td>{coin.characteristicMaterial}</td>
                      </tr>
                    )}
                    {coin.characteristicMetal && (
                      <tr>
                        <td>{text.single.metal}</td>
                        <td>{coin.characteristicMetal}</td>
                      </tr>
                    )}
                    {coin.characteristicWeight && (
                      <tr>
                        <td>{text.single.weight}</td>
                        <td>{coin.characteristicWeight} g</td>
                      </tr>
                    )}
                    {coin.characteristicDiameter && (
                      <tr>
                        <td>{text.single.diameter}</td>
                        <td>{coin.characteristicDiameter} mm</td>
                      </tr>
                    )}
                    {coin.characteristic?.thickness3 && (
                      <tr>
                        <td>{text.single.thickness}</td>
                        <td>{coin.characteristicThickness} mm</td>
                      </tr>
                    )}
                    {coin.characteristicMint && (
                      <tr>
                        <td>{text.single.manufactor}</td>
                        <td>{coin.characteristicMint}</td>
                      </tr>
                    )}
                    {coinSerie && (
                      <tr>
                        <td>{text.single.series}</td>
                        <td>
                          {isInFilter && (
                            <a
                              css={`
                                text-decoration: underline;
                              `}
                              href={
                                locale === "fi-FI"
                                  ? `/?serie=${coin.enSerieValue.items[0]?.serieName}`
                                  : `/en/?serie=${coin.enSerieValue.items[0]?.serieName}`
                              }
                            >
                              {coinSerie}
                            </a>
                          )}
                          {!isInFilter && <>{coinSerie}</>}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <h2 className="mintagesTitle">{text.single.mintages.title}</h2>
                <table
                  className="mintagesBox"
                  css={`
                    border-collapse: collapse;
                    min-width: 100%;
                    max-width: 400px;
                    margin-bottom: 50px;
                    tr {
                      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    }
                    th {
                      width: 20%;
                      padding: 6px 0;
                      text-align: left;
                      &:not(:first-child) {
                        text-align: right;
                      }
                    }

                    td {
                      padding: 6px 0;
                      &:not(:first-child) {
                        text-align: right;
                      }
                    }
                  `}
                >
                  <tbody>
                    <tr>
                      <th>{text.single.mintages.year}</th>
                      <th>{text.single.mintages.unc}</th>
                      <th>{text.single.mintages.bu}</th>
                      <th>{text.single.mintages.proof}</th>
                    </tr>

                    {mintagesGroupedByYearsArr.map(item => {
                      let unc = item.find(
                        mintage => mintage?.mintageType === "unc"
                      );
                      let unc2 = item.find(mintage => !mintage.mintageType);
                      let bu = item.find(
                        mintage => mintage?.mintageType === "bu"
                      );
                      let proof = item.find(
                        mintage =>
                          mintage?.mintageType === "proof" && !mintage?.numbered
                      );
                      let proofNumbered = item.find(
                        mintage =>
                          mintage?.mintageType === "proof" && mintage?.numbered
                      );

                      return (
                        <tr key={item[0].year + item[0].mintage}>
                          <td>{item[0].year}</td>
                          <td>
                            {unc?.mintage?.toLocaleString("fi-FI") ||
                              unc2?.mintage?.toLocaleString("fi-FI")}
                          </td>
                          <td>{bu?.mintage?.toLocaleString("fi-FI")}</td>
                          <td>
                            {proof?.mintage?.toLocaleString("fi-FI")}{" "}
                            {proofNumbered && (
                              <>
                                <br />
                                {proofNumbered.mintage.toLocaleString("fi-FI")}
                                &nbsp;(
                                {text.single.mintages.numbered})
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div
                  css={`
                    p {
                      margin-top: 0;
                      font-weight: 600;
                    }
                  `}
                >
                  {coin.customFooterTextCollection?.items[0]?.text && (
                    <p>{coin.customFooterTextCollection?.items[0]?.text}</p>
                  )}
                  {!coin.customFooterTextCollection?.items[0]?.text && (
                    <>
                      {coin.commemorative == 1 && (
                        <p>{globalText.tekstiJuhlaraha}</p>
                      )}
                      {coin.commemorative == 0 && (
                        <p>{globalText.tekstiKayttoraha}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </article>
        {relatedCoins.length === 0 && (
          <section
            css={`
              max-width: 1400px;
              width: 100%;
              margin: 0 auto;
            `}
          >
            <button
              onClick={() => window.history.back()}
              className="button"
              css={`
                background: none;
                border: none;
                align-self: flex-start;
                padding-left: 0;
                color: rgba(0, 0, 0, 0.6);
                letter-spacing: 0.02em;
                svg {
                  margin-right: 6px;
                }
              `}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                  fill="rgba(0, 0, 0, 0.6)"
                />
              </svg>
              <span>{text.single.goBack}</span>
            </button>
          </section>
        )}
      </main>
      {relatedCoins.length > 0 && (
        <>
          <section
            css={`
              flex-direction: column;
              padding: 80px 10px;
              h2 {
                text-align: center;
              }
            `}
          >
            <RelatedCoins
              relatedCoins={relatedCoins}
              locale={pageContext.locale}
              relatedHeading={relatedHeading}
            />
          </section>
          <section
            css={`
              max-width: 1400px;
              width: 100%;
              margin: 0 auto;
            `}
          >
            <button
              onClick={() => window.history.back()}
              className="button"
              css={`
                background: none;
                border: none;
                align-self: flex-start;
                padding-left: 0;
                margin: 0 auto 40px;
                color: rgba(0, 0, 0, 0.6);
                letter-spacing: 0.02em;
                svg {
                  margin-right: 6px;
                }
              `}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                  fill="rgba(0, 0, 0, 0.6)"
                />
              </svg>
              <span>{text.single.goBack}</span>
            </button>
          </section>
        </>
      )}
    </Layout>
  );
};

export const query = graphql`
  query CoinQuery($id: String!, $locale: String) {
    contentful {
      slugEn: databaseCoin(id: $id, locale: "en-US") {
        slug
      }
      slugFi: databaseCoin(id: $id, locale: "fi-FI") {
        slug
      }
      coin: databaseCoin(id: $id, locale: $locale) {
        coinid
        title
        slug
        series
        seriesRefCollection(limit: 3) {
          items {
            serieName
          }
        }
        enSerieValue: seriesRefCollection(limit: 3, locale: "en-US") {
          items {
            serieName
          }
        }
        topic
        years
        faceValue
        faceValueDropdown
        customFooterTextCollection {
          items {
            text
          }
        }
        currency
        currencyDropdown
        country
        commemorative
        manufactor
        characteristicDiameter
        characteristicWeight
        characteristicMaterial
        characteristicMetal
        characteristicThickness
        characteristicMint
        designer
        descriptionText
        imagesCollection(limit: 8) {
          items {
            position
            imageUrl
            source
          }
        }
        mintagesCollection(limit: 30, locale: "en-US") {
          items {
            mintage
            year
            numbered
            mintageType
          }
        }
      }
      global: kolikkogalleriaMuuSislt(
        id: "798WUDW9uxJnMHJ4pvzdfg"
        locale: $locale
      ) {
        tekstiJuhlaraha
        tekstiKayttoraha
      }
      series: seriesCollection {
        items {
          serieName(locale: $locale)
          useInFilters
        }
      }
      coins: databaseCoinCollection(
        limit: 1000
        where: { country: "Finland" }
      ) {
        items {
          slug(locale: $locale)
          commemorative
          currency
          currencyDropdown
          faceValue
          title(locale: $locale)
          seriesRefCollection(limit: 3) {
            items {
              serieName(locale: $locale)
            }
          }
          imagesCollection(limit: 1) {
            items {
              position
              imageUrl
            }
          }
          years
          coinid: sys {
            id
          }
        }
      }
    }
  }
`;

export default Coin;
